import { FileUpload } from 'o365-fileupload';
import { $t } from 'o365-utils';
import { DataObject } from 'o365-dataobject';

declare module "o365-dataobject" {
    interface DataObject {
        _importData: DataObjectImportData;
        importData: DataObjectImportData
    }
}

Object.defineProperty(DataObject.prototype, "importData", { get: function importData() { 
    if(!this._importData) this._importData = new DataObjectImportData(this);

    return this._importData;
} });

class DataObjectImportData {
    private _dataObject:DataObject;
    private _files:Array<File> | undefined | null;
    private _fileUpload:FileUpload;
    private _records:Array<any>;
    private static _exifReader : any;
    beforeUpload:Function | undefined;
    onCompleted:Function | undefined;
    progress:ProgressHandler;

    uploading:boolean = false;

    set files(pList){
        if(pList && pList.constructor === FileList){
            this._files = Array.from(pList);
        }else{
            this._files = pList;
        }
    }

    get files(){
        return this._files;
    }

    set useChunks(pValue:string){
        this._fileUpload.useChunks = !!pValue;
    }
    set url(pValue:string){
        this._fileUpload.url = pValue;
    }

    constructor(pDataObject:DataObject){
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
            url:`/nt/api/data/import/excel-import/${this._dataObject.uniqueTable || this._dataObject.viewName}`,
            useChunks: false,
            viewName: this._dataObject.viewName
        });
 
        this.progress = new ProgressHandler();
        this._records = [];
    }

    async upload(pOptions:any = null, pData:any){
        if (typeof pOptions.files[0] === 'string') {
            pOptions.files[0] = this.dataURIToFile(pOptions.files[0]);
        }

        if(pOptions.files){
            this.files = pOptions.files;
        }

        const addExifData = this._dataObject.fields.hasOwnProperty("ExifData");
        if (addExifData && !DataObjectImportData._exifReader) {
            DataObjectImportData._exifReader = (await import('exifreader')).default;
        }

        const vUploads = [];
      
        let vBeforeUploadParams :any;
        if(!this._files) return Promise.resolve();
        if(!this._files.length) return Promise.resolve();
        if(this._files.length === 0) return Promise.resolve();
        this.uploading = true;
        this.progress.start(pOptions);
        this.progress.message = `${$t('Starting to upload')} ${this.files?.length} ${$t('files')}`;
        
        let vData :any = {};
        if (this._dataObject.masterDetails.isSet) {
            vData = Object.assign(vData, this._dataObject.masterDetails.getMasterDetailRowForInsert());
        }
        if(this.beforeUpload) vBeforeUploadParams = this.beforeUpload.call(this,...arguments);
        if(vBeforeUploadParams === false) return Promise.resolve();
        if(vBeforeUploadParams && typeof vBeforeUploadParams == "object"){
            Object.keys(vBeforeUploadParams).forEach(key=>{
                vData[key] = vBeforeUploadParams[key];
            })
        }
        vBeforeUploadParams = null;
        if(pOptions && pOptions.beforeUpload) vBeforeUploadParams = pOptions.beforeUpload.call(this,...arguments);
        if(pOptions && vBeforeUploadParams === false) return Promise.resolve();
        if(pOptions && vBeforeUploadParams && typeof vBeforeUploadParams == "object"){
            Object.keys(vBeforeUploadParams).forEach(key=>{
                vData[key] = vBeforeUploadParams[key];
            })
        }
        if(pData){
            vData = Object.assign(pData,vData);
        }
        this._records = [];
        for (let i = 0; i < this._files.length; i++) {
            let vFile = this._files[i];

            let fileData = vData;
            
            vUploads.push(this.uploadFile2(vFile,fileData))
 
        }

        return Promise.all(vUploads).then((data : any)=>{
            if(!data || !data[data.length-1]) return;
            this.uploading = false;
            
            if(pOptions.onCompleted) pOptions.onCompleted.call(this,...arguments);
            if(this.onCompleted) this.onCompleted.call(this,...arguments);
            this.progress.message = `${$t('Upload complete')}`;
            this.progress.completeUpload();
            this._dataObject.load();
            return data;
        }).catch((e)=>{
            //wht to do ?
            console.error(e);
            if(pOptions.onError) pOptions.onError.call(this,...arguments);
            this.progress.message = `${$t('Error')}`;
            this.uploading = false;
            this._dataObject.load();
        });


    }

    cancel(){
        this.uploading = false;
        this.files = null;
        this.progress.message = `${$t('Canceling upload')}`;
        this._records.forEach(rec=>{
            if(rec.primKey){
                this._dataObject.cancelChanges(rec.item.index);
            }else{
                this._dataObject.deleteItem(rec.item);
            }
        });
        this._records = [];
        this._fileUpload.abort();
    }

    async uploadFile2(pFile:File,pData:any){
        const vRef = await this._uploadFile2(pFile,pData);
        console.log(vRef);
        pData["FileRef"] = vRef['FileRef'];
        this.progress.message = `${$t('Uploading file: ')} ${pFile.name}`;
        return vRef
    }

    async _uploadFile2(pFile:File,pData:any){
        return await this._fileUpload.upload({
            file:pFile,
            data:pData,
         //   uploadRef: pPrimKey,
            onProgress:(e)=>this.progress.updateProgress(pFile,e)
        })
    }

    private dataURIToFile(data: string) {
        if (!data.includes('base64')) return;
        const src = data.split('src="')[1].split('"')[0]; 

        const base64String = src.split(',')[1];
        const byteString = atob(base64String);
        const mimeString = src.split(':')[1].split(';')[0];

        const buffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(buffer);

        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([buffer], { type: mimeString });

        const file = new File([blob], mimeString.replace('/', '.'), { lastModified: Date.now() })
        return file;
    }

}

class ProgressHandler{
    private items:Map<string,any>;
   // items:FileList;
    private _currentProgress:number|null = null;
    //private _totals:object;
    private _message:string|null = null;

    inProgress:boolean=false;
    
    uploaded:number = 0;
    total:number = 0;
    error:string = "";
    set message(pMessage){
        this._message = pMessage;
        this.fireOnProgress();
    }
    get message(){
        return this._message;
    }


    onProgress:Function|null = null;
    get currentProgress(){
        return this._currentProgress;
    }

    get totals(){
        return {
            files:this.items,
            total:this.total,
            uploaded:this.uploaded,
            progress:this.uploaded !== 0?Math.min(Math.round((this.uploaded/this.total)*100),100):0,
            completed:this.uploaded===this.total,
            error:this.error,
            message:this.message
        }
    }

    set currenProgress(pVal:number){
        this._currentProgress = pVal;
    }
    get progress(){
        return this.items
    }
    constructor(){
        this.items = new Map();
    }

    start(pOptions:any){
        this.reset();
        this.inProgress = true;
       
        for (let i = 0; i < pOptions.files.length; i++) {
            let vFile = pOptions.files[i];
            this.items.set(vFile,new Progress(vFile));
            this.total += vFile.size;

        }
        this.onProgress = pOptions.onProgress;

    }
    updateError(pFile:string,pMessage:string){
        const vItem = this.items.get(pFile);
        vItem.error = pMessage;
        this.error = pMessage;
        this.message = null;
        this.inProgress = false;
         this.fireOnProgress();
    }
    updateProgress(pFile:string,pParams:any){

            const vItem = this.items.get(pFile);
            vItem.uploaded = Math.min(pParams.loaded,vItem.total);
            this.uploaded = 0;
        
            this.items.forEach((val)=>{
                this.uploaded += val.uploaded;
                
            })
       

       // console.log(this.totals.progress,this.totals.uploaded,this.totals.total);
        this.fireOnProgress();
    }

    fireOnProgress(){
        if(this.onProgress) this.onProgress.call(this,this.totals);
    }
    cancel(){
        this.reset();
    }

    completeUpload(){
        this.reset();
        this.inProgress = false;
    }

    reset(){
       
        this.items = new Map();
        

        this.total = 0;
        this.uploaded = 0;
        this.error = null;
   
   
    }
}

class Progress{
    private _uploaded:number = 0;
    name:string;
    path:string;
    total:number;
    error:string|null = null;
    
    set uploaded(pValue){
        if(this.total < pValue){
            this._uploaded = this.total;
        }else{
            this._uploaded = pValue;
        }
    }

    get uploaded(){
        return this._uploaded;
    }
   


    get completed(){
        return this.uploaded >= this.total;
    }
    get id(){
        if(this.path){
            return this.path + "/"+this.name;
        }

        return this.name;
    }

    constructor(pFile){
        this.total = pFile.size;
        this.name = pFile.name;
        this.path = pFile.path;
    }
}